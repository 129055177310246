<template>
    <div class="w-full h-full flex flex-col p-6 relative white flex overflow-hidden">
        <div class="flex h-screen">
            <div class="m-auto">
                <h1>Zeus Auth Testing API - TOKEN</h1><br>

                <button
                    @click="getGreetings"
                    class="px-4 py-1 bg-indigo-500 font-medium text-indigo-100 rounded-lg">
                    Get the greetings!
                </button>
                &nbsp;
                <button
                    @click="logout"
                    class="px-4 py-1 bg-warning font-medium text-indigo-100 rounded-lg">
                    Logout!
                </button>
            </div>
        </div>
    </div>
</template>


<script>

import { actions } from '@/store';
import Hello from '@/api/models/test.js'

export default {
    name: 'Home',

    data() {
        return {}
    },

    methods: {
        getGreetings() {
            this.test = Hello.all({});
        },

        logout(){
            actions.resetAuth();
            actions.setUser(null);
            this.$router.push('login');
        }
    }


}
</script>